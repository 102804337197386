
import GateNotificationTooltip from 'razlet-sdk/lib/components/gate/notification/tooltip';
import {mapGetters} from 'vuex';
import { USER, AGENT, CORPORATE, AIRPORT_CASHIER } from 'razlet-sdk/lib/constants/roles';
import {BALANCE, CREDIT, MOTIVATION} from 'razlet-sdk/lib/store/auth/getters.type';
import AuthModal from 'razlet-sdk/lib/components/auth/auth-modal';
import ContractsModal from 'razlet-sdk/lib/components/auth/contracts-modal';

export default {
  name: 'login-menu',
  components: { 
    AuthModal,
    ContractsModal,
    GateNotificationTooltip,
  },
  data() {
    return {
      isModal: false,
      isMenuShowed: false,
      initialLoading: true,
      isSwitchContractModal: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'loading']),
    ...mapGetters('auth', [BALANCE, CREDIT, MOTIVATION]),
    isLoading() {
      if (typeof window === 'undefined') {
        return true;
      }
      return this.loading || typeof this.user === 'undefined';
    },
    userName() {
      const {user} = this;
      if (typeof user === 'undefined' || user.token === null) {
        return '';
      }
      return `${user.model.surname} ${user.model.name}`;
    },
    profileMenu() {
      return [
        { label: this.$t('menu.profile'), route: 'account-profile', access: [USER] },
        { label: this.$t('menu.orders'), route: 'account-orders', access: [USER] },
        { label: this.$t('menu.upsale'), route: 'account-upsale', access: [AIRPORT_CASHIER] },
        { label: this.$t('menu.operations'), route: 'account-operations', access: [USER] },
        { label: this.$t('menu.requests'), route: 'account-requests', access: [CORPORATE, AGENT] },
        { label: this.$t('menu.tickets'), route: 'account-tickets', access: [USER] },
        { label: this.$t('menu.passengers'), route: 'account-passengers', access: [USER] },
      ];
    },
    filteredProfileMenu() {
      if (!this.isUser || !this.user.model.roles) return [];
      return this.profileMenu.filter(item => !item.access.length || item.access.some(v => this.user.model.roles.includes(v)));
    },
  },
  watch: {
    '$route': {
      handler() {
        this.isMenuShowed = false;
      },
      deep: true,
    },
  },
  methods: {
    logout() {
      this.$bus.$emit('logout');
      this.isMenuShowed = false;
    },
    outSideClick() {
      if (this.isMenuShowed) {
        this.isMenuShowed = false;
      }
    },
    goTo(menuItem) {
      this.isMenuShowed = false;
      if (menuItem.href) window.location.href = menuItem.href;
      else this.$router.push({name: menuItem.name});
    },
  },
};
