import { render, staticRenderFns } from "./desktop-header.vue?vue&type=template&id=6ff8c7c6"
import script from "./desktop-header.vue?vue&type=script&lang=js"
export * from "./desktop-header.vue?vue&type=script&lang=js"
import style0 from "./desktop-header.vue?vue&type=style&index=0&id=6ff8c7c6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzIconLogo: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-logo/index.vue').default,RzLangSwitcher: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/components/lang-switcher/index.vue').default,RzThemeSwitcher: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/components/theme-switcher/index.vue').default,RzIconDropArrow: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-drop-arrow/index.vue').default,RzDropdown: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/components/dropdown/index.vue').default,LoginMenu: require('/opt/razlet.ru.v2/src/components/login/menu.vue').default,RzContainer: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/components/container/index.vue').default})
