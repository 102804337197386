
import {mapGetters} from 'vuex';
import GateNotificationTooltip from 'razlet-sdk/lib/components/gate/notification/tooltip';
import GateNotificationCenter from 'razlet-sdk/lib/components/gate/notification/center';
import WidgetComponent from 'razlet-sdk/lib/components/chat/widget/widget-component';
import BonusesView from 'razlet-sdk/lib/components/account/bonuses-view';
import PricingView from 'razlet-sdk/lib/components/avia/pricing/pricing-view';
import auth from '~/mixins/auth';
import search from '~/mixins/search';
import access from '~/mixins/access';
import FooterBlock from '~/components/footer-block';
import MainHeader from '~/components/main-header';

const pricingFormRoutes = [
  'search-uid', 
];
const withoutContainerRoutes = [
  'all',
  'bad-access',
  'index', 
  'login', 
  'citylist', 
  'contacts',
  'bid-return-id',
  'bid-return-create-id',
  'news-all',
  'news',
  'order-id-refund',
  'order-id-refund-voluntary',
  'flight-departure-arrival',
];

export default {
  components: {
    FooterBlock, 
    PricingView, 
    MainHeader,
    BonusesView,
    GateNotificationTooltip,
    GateNotificationCenter,
    WidgetComponent,
  },
  mixins: [auth, search, access],
  computed: {
    ...mapGetters(['isPushRegistered']),
    isRouteWithPricingForm() {
      return pricingFormRoutes.includes(this.$route.name);
    },
    isRouteWithoutContainer() {
      return withoutContainerRoutes.includes(this.$route.name);
    },
    isRouteAccount() {
      return this.$route.name.startsWith('account-');
    },
    layoutClasses() {
      let classes = `theme--${this.currentTheme}`;
      if (this.isAgentSite) classes += ` theme--agent-${this.currentTheme}`;
      return classes;
    },
  },
  watch: {
    '$route': {
      handler(value) {
        if (value) {
          if (this.$refs.widget) this.widgetMounted();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.$store.commit('setMobile', window.innerWidth <= 992);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.$store.commit('setMobile', window.innerWidth <= 992);
    });
  },
  methods: {
    widgetMounted() {
      if (this.$route.query && this.$route.query.chatId) {
        this.$bus.$emit('widget-open-chat', this.$route.query.chatId, true);
        const query = {...this.$route.query};
        delete query.chatId;
        this.$router.replace({...this.$route, query});
      }
    },
  },
};
