import { render, staticRenderFns } from "./menu.vue?vue&type=template&id=28d9f97c"
import script from "./menu.vue?vue&type=script&lang=js"
export * from "./menu.vue?vue&type=script&lang=js"
import style0 from "./menu.vue?vue&type=style&index=0&id=28d9f97c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzDottedLoader: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/components/dotted-loader/index.vue').default,RzIconDropArrow: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-drop-arrow/index.vue').default,RzIconSwap: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/icons/icon-swap/index.vue').default,RzDropdown: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/components/dropdown/index.vue').default})
