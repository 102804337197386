import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6a13a290&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=6a13a290&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a13a290",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzOfflineModal: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/components/offline-modal/index.vue').default,RzOffline: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/components/offline/index.vue').default,RzNotificationCenter: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/components/notification-center/index.vue').default,MainHeader: require('/opt/razlet.ru.v2/src/components/main-header.vue').default,RzVerticalMenu: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/components/vertical-menu/index.vue').default,RzContainer: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/components/container/index.vue').default,RzDeviceView: require('/opt/razlet.ru.v2/node_modules/razlet-ui/src/components/device-view/index.vue').default,FooterBlock: require('/opt/razlet.ru.v2/src/components/footer-block.vue').default})
